import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from '../context/global/globalContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import TableIcons from '../components/layout/MaterialTableIcons';
import ReclamationsForm from '../components/ReclamationsForm';
import Loading from '../components/layout/Loading';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import StatusChipSC from '../components/layout/StatusChipSC';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '104px',
    marginBottom: '32px'
  },
  detailRow: {
    padding: theme.spacing(6)
  },
  detailRowContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(2)
  },
  table: {
    boxShadow: '0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07)'
  }
}));

const DetailRow = props => (
  <Container className={props.classes.detailRowContainer}>
    <Card className={props.classes.card}>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='flex-start'
        spacing={2}
      >
        <Grid item>
          <Typography variant='subtitle2'>Claim ID</Typography>
          <Typography variant='body2'>{props.data.reclamationId}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>Link</Typography>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={props.data.link}
          >
            Link to additional material
          </Link>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>Decision</Typography>
          <Typography variant='body2'>{props.data.decision}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle2'>Description</Typography>
          <Typography variant='body2'>{props.data.description}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Checked</TableCell>
              <TableCell>Rejected</TableCell>
              <TableCell>Return</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.SupplierQFailures.map(failure => (
              <TableRow key={failure.failureId}>
                <TableCell>{failure.itemId}</TableCell>
                <TableCell>{failure.itemName}</TableCell>
                <TableCell>{failure.qtyCheck}</TableCell>
                <TableCell>{failure.qtyOrig}</TableCell>
                <TableCell>{failure.qtyReject}</TableCell>
                <TableCell>{failure.qtyReturn}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Card>
  </Container>
);

const Reclamations = () => {
  const globalContext = useContext(GlobalContext);
  const { getReclamations, reclamations, loading } = globalContext;

  const [checked, setChecked] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [currentReclamation, setCurrentReclamation] = useState({});

  useEffect(() => {
    if (reclamations.length === 0) {
      getReclamations();
    }
    setChecked(true);
    //eslint-disable-next-line
  }, []);

  const openReclamations = reclamationObject => {
    setCurrentReclamation(reclamationObject);
    setOpenReport(true);
  };

  const clearCurrentReclamation = () => {
    setOpenReport(false);
    setTimeout(() => {
      setCurrentReclamation({});
    }, 500);
  };

  const [table] = useState({
    cells: [
      {
        title: 'Claim ID',
        field: 'reclamationId'
      },
      { title: 'Date', field: 'reclamationDate' },
      { title: 'Contact', field: 'contact' },
      { title: 'Supplier Ref.', field: 'sellersRef' },

      { title: 'Order ID', field: 'purOrderId' },
      { title: 'Transtech Ref.', field: 'buyersRef' },
      {
        title: 'Repair Hours',
        field: 'load',
        type: 'numeric',
        grouping: false,
        filtering: false,
        render: rowData => `${rowData.load} h`
      },
      {
        title: 'Status',
        field: 'SupplierxD.status',
        grouping: false,
        lookup: {
          R: 'Unresolved',
          Y: 'Pending',
          G: 'Resolved'
        },
        render: rowData => <StatusChipSC status={rowData.SupplierxD.status} />
      },
      {
        title: null,
        field: 'SupplierxD',
        filtering: false,
        grouping: false,
        sorting: false,
        disableClick: true,
        render: rowData => (
          <Button
            color='primary'
            onClick={() => openReclamations(rowData.SupplierxD)}
          >
            View
          </Button>
        )
      }
    ],
    options: {
      grouping: true,
      search: false,
      filtering: true,
      paginationType: 'stepped',
      pageSize: 10,
      pageSizeOptions: [10, 15, 20]
    }
  });

  const classes = useStyles();

  return loading ? (
    <Loading />
  ) : (
    <Fade in={checked}>
      <Container maxWidth='xl' className={classes.container}>
        <Card className={classes.table}>
          <MaterialTable
            icons={TableIcons}
            title='Reclamations'
            columns={table.cells}
            data={reclamations}
            options={table.options}
            localization={{
              body: {
                emptyDataSourceMessage: loading
                  ? 'Loading data...'
                  : 'No data to display.'
              },
              filterRow: {
                placeHolder: 'Filter...'
              }
            }}
            detailPanel={rowData => (
              <DetailRow data={rowData} classes={classes} />
            )}
          />
          <ReclamationsForm
            open={openReport}
            onClose={() => clearCurrentReclamation()}
            supplierxD={currentReclamation}
          />
        </Card>
      </Container>
    </Fade>
  );
};

export default Reclamations;
