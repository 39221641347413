// Global types
export const GET_QUARTERS = 'GET_QUARTERS';
export const SET_CURRENTQUARTER = 'SET_CURRENTQUARTER';
export const GET_SCORINGLINES = 'GET_SCORINGLINES';
export const GET_SUPPLIERSCORING = 'GET_SUPPLIERSCORING';
export const GET_SUPPLIERSCORINGSTOCHARTS = 'GET_SUPPLIERSCORINGSTOCHARTS';
export const SET_LOADING = 'SET_LOADING';
export const END_LOADING = 'END_LOADING';
export const GET_RECLAMATIONS = 'GET_RECLAMATIONS';
export const UPDATE_RECLAMATIONS = 'UPDATE_RECLAMATIONS';
export const SET_UPLOADING = 'SET_UPLOADING';
export const UPLOADING_FAILED = 'UPLOADING_FAILED';
export const CLEAR_STATE = 'CLEAR_STATE';

// Auth types
export const TOKEN_LEASED = 'TOKEN_LEASED';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const REDIRECT = 'REDIRECT';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RENEW_TOKEN = 'RENEW_TOKEN';

// Admin types
export const GET_USERS = 'GET_USERS';
export const SET_CURRENTUSER = 'GET_CURRENTUSER';
export const CLEAR_CURRENTUSER = 'CLEAR_CURRENTUSER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
