import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import ArrowForwardRounded from '@material-ui/icons/ArrowForwardRounded';
import styled from 'styled-components';

const ArrowRight = styled(ArrowForwardRounded)`
  text-align: center;
  color: #fff;
  font-size: 75px;
`;

const ArrowDown = styled(ArrowDownwardRounded)`
  text-align: center;
  color: #fff;
  font-size: 75px;
`;
const ArrowUp = styled(ArrowUpwardRounded)`
  text-align: center;
  color: #fff;
  font-size: 75px;
`;

const CustomIcon = ({ direction }) =>
  direction === 'DN' ? (
    <ArrowDown id='arrow-down' />
  ) : direction === 'UP' ? (
    <ArrowUp id='arrow-up' />
  ) : direction === 'RI' ? (
    <ArrowRight id='arrow-right' />
  ) : (
    '-'
  );

const CustomBox = styled(Box)`
  height: 100px;
  width: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: ${props =>
    props.color === 'R'
      ? '#F00000'
      : props.color === 'G'
      ? '#00B050'
      : props.color === 'Y'
      ? '#FFCC00'
      : '#A9A9A9'};
`;

const TrafficLightsBoxSC = ({ direction, color }) => {
  return (
    <Tooltip
      arrow
      title={
        direction === 'DN'
          ? 'Worse score than last quarter'
          : direction === 'RI'
          ? 'Equal score with last quarter'
          : direction === 'UP'
          ? 'Better score than last quarter'
          : 'Not available'
      }
    >
      <CustomBox color={color}>
        <CustomIcon direction={direction} />
      </CustomBox>
    </Tooltip>
  );
};

export default TrafficLightsBoxSC;
