import React, { useEffect, useContext } from 'react';
import AdminUserTable from '../components/AdminUserTable';
import AdminContext from '../context/admin/adminContext';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '60vh',
    marginTop: '104px',
    marginBottom: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const AdminDashboard = props => {
  const adminContext = useContext(AdminContext);

  const { getAllUsers } = adminContext;

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <AdminUserTable />
    </Container>
  );
};

export default AdminDashboard;
