import React, { useReducer } from 'react';
import axios from 'axios';
import decode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import AuthContext from './authContext';
import authReducer from './authReducer';
import {
  INVALID_CREDENTIALS,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REDIRECT,
  SET_LOADING,
  RENEW_TOKEN,
} from '../types';

const AuthState = (props) => {
  const initialState = {
    token: sessionStorage.getItem('token'),
    userid:
      sessionStorage.getItem('token') !== null
        ? decode(sessionStorage.getItem('token')).user[0].userId
        : null,
    refreshToken: sessionStorage.getItem('refreshtoken'),
    isAuthenticated: sessionStorage.getItem('token') !== null ? true : null,
    privilege:
      sessionStorage.getItem('token') !== null
        ? decode(sessionStorage.getItem('token')).user[0].privileges
        : null,
    loading: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  };

  // Login user
  const login = async (formData) => {
    setLoading();
    try {
      const res = await axios.post(
        process.env.REACT_APP_API + '/login',
        formData,
        config
      );

      if (res.status === 200) {
        enqueueSnackbar('Given credentials matched perfectly.', {
          variant: 'success',
        });
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      if (error.response.status === 401) {
        enqueueSnackbar('Given credentials do not match.', {
          variant: 'error',
        });
      }
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

  const renewToken = async () => {
    const tokenCredentials = {
      userid: sessionStorage.userid,
      refreshtoken: sessionStorage.getItem('refreshtoken'),
    };
    // console.log('Checking token validity...');
    const decoded = decode(sessionStorage.getItem('token'));
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      // console.log('Token has expired. Renewing token.');
      try {
        const res = await axios.post(
          process.env.REACT_APP_API + '/token',
          tokenCredentials,
          config
        );

        // console.log(res);

        dispatch({
          type: RENEW_TOKEN,
          payload: res.data,
        });
      } catch (error) {
        enqueueSnackbar('Token expired, redirecting to login page.', {
          variant: 'error',
        });
        dispatch({
          type: INVALID_CREDENTIALS,
        });
      }
    }
  };

  // Logout
  const logout = () => {
    enqueueSnackbar('You have been logged out.', {
      variant: 'info',
    });
    dispatch({
      type: LOGOUT,
    });
  };

  // Redirect
  const redirect = () => {
    dispatch({
      type: REDIRECT,
    });
  };

  const setLoading = () => {
    dispatch({
      type: SET_LOADING,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
        userid: state.userid,
        loading: state.loading,
        privilege: state.privilege,
        login,
        logout,
        redirect,
        // leaseNewJwt,
        renewToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
