import { GET_USERS, ADD_USER, UPDATE_USER, DELETE_USER } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user =>
          user.userId === action.payload.userId ? action.payload : user
        ),
        loading: false
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.userId !== action.payload)
      };

    default:
      return state;
  }
};
