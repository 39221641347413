import React, { useEffect, useContext, useState } from "react";
import AdminContext from "../context/admin/adminContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  TableEditColumn,
  TableEditRow,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  EditingState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  SearchState
} from "@devexpress/dx-react-grid";
import { Card, CardHeader, IconButton, Tooltip } from "@material-ui/core";
import {
  FaUserEdit,
  FaRegTrashAlt,
  FaUserCheck,
  FaUserPlus,
  FaTimes
} from "react-icons/fa";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "1.2rem"
  }
}));

const AdminUserTable = props => {
  const adminContext = useContext(AdminContext);

  const { users, addUser, updateUser, deleteUser } = adminContext;

  const getRowId = row => row.userId;

  const [usersData, setUsersData] = useState([]);

  const classes = useStyles();

  const [state] = useState({
    columns: [
      { name: "userId", title: "User ID" },
      { name: "password", title: "Password" }
    ],
    stringFilterOpertions: ["contains", "startsWith", "endsWith"],
    pageSizes: [15, 25, 50],
    tableColumnExtensions: [
      { columnName: "userId", align: "center" },
      { columnName: "password", align: "center" }
    ],
    editingStateColumnExtensions: [
      {
        columnName: "userId",
        editingEnabled: false
      }
    ],
    sortingStateColumnExtensions: [
      {
        columnName: "password",
        sortingEnabled: false
      }
    ]
  });

  useEffect(() => {
    setUsersData(users);
  }, [users]);

  const AddButton = ({ onExecute }) => (
    <Tooltip title='Create new user' placement='left'>
      <IconButton color='primary' onClick={onExecute}>
        <FaUserPlus className={classes.icon} />
      </IconButton>
    </Tooltip>
  );

  const EditButton = ({ onExecute }) => (
    <Tooltip title='Edit user' placement='left'>
      <IconButton color='primary' onClick={onExecute}>
        <FaUserEdit className={classes.icon} />
      </IconButton>
    </Tooltip>
  );

  const DeleteButton = ({ onExecute }) => (
    <Tooltip title='Delete user' placement='right'>
      <IconButton
        onClick={() => {
          // eslint-disable-next-line
          if (window.confirm("Are you sure you want to delete this user?")) {
            onExecute();
          }
        }}
      >
        <FaRegTrashAlt className={classes.icon} />
      </IconButton>
    </Tooltip>
  );

  const CommitButton = ({ onExecute }) => (
    <Tooltip title='Save changes' placement='left'>
      <IconButton color='primary' onClick={onExecute}>
        <FaUserCheck className={classes.icon} />
      </IconButton>
    </Tooltip>
  );

  const CancelButton = ({ onExecute }) => (
    <Tooltip title='Cancel changes' placement='right'>
      <IconButton onClick={onExecute}>
        <FaTimes className={classes.icon} />
      </IconButton>
    </Tooltip>
  );

  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton
  };

  const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      addUser(added[0]);
      // console.log('User Added' + JSON.stringify(added[0]));
    }
    if (changed) {
      // console.log({
      //   userId: Object.keys(changed)[0],
      //   password: changed[Object.keys(changed)].password
      // });
      updateUser({
        userId: Object.keys(changed)[0],
        password: changed[Object.keys(changed)].password
      });
    }
    if (deleted) {
      deleteUser(deleted[0]);
      // console.log('User Deleted ' + deleted[0] + JSON.stringify(deleted));
    }
  };

  const Cell = ({ tableRow, editingEnabled, ...restProps }) => (
    <TableEditRow.Cell
      tableRow={tableRow}
      editingEnabled={
        tableRow.type === TableEditRow.ADDED_ROW_TYPE ? true : editingEnabled
      }
      {...restProps}
    />
  );

  return (
    <Card>
      <CardHeader title='Users' subheader='List of all users' />
      <Grid rows={usersData} columns={state.columns} getRowId={getRowId}>
        <SearchState />
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={state.editingStateColumnExtensions}
        />
        <SortingState
          columnExtensions={state.sortingStateColumnExtensions}
          defaultSorting={[{ columnName: "userId", direction: "asc" }]}
        />
        <IntegratedSorting />
        <IntegratedFiltering />

        <PagingState defaultCurrentPage={0} defaultPageSize={15} />
        <IntegratedPaging />
        <VirtualTable columnExtensions={state.tableColumnExtensions} />
        <TableHeaderRow showSortingControls />
        <TableEditRow cellComponent={Cell} />
        <TableEditColumn
          showAddCommand
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />

        <Toolbar />
        <SearchPanel />
        <PagingPanel pageSizes={state.pageSizes} />
      </Grid>
    </Card>
  );
};

export default AdminUserTable;
