import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

const CustomBox = styled(Box)`
  height: 100px;
  width: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: ${props =>
    props.escalation === 'L0'
      ? '#00B050'
      : props.escalation === 'L1'
      ? '#FFCC00'
      : props.escalation === 'L2'
      ? '#FF6600'
      : props.escalation === 'L3'
      ? '#F00000'
      : props.escalation === 'L4'
      ? '#000000'
      : '#A9A9A9'};
`;

const CustomText = styled(Typography)`
  text-align: center;
  color: #fff;
  font-weight: 500;
`;

const EscalationBox = ({ escalation }) => {
  return (
    <Tooltip
      arrow
      title={
        escalation === 'L0'
          ? 'Good Performing Supplier (L0)'
          : escalation === 'L1'
          ? 'Analyze (L1)'
          : escalation === 'L2'
          ? 'Alert (L2)'
          : escalation === 'L3'
          ? 'Alarm (L3)'
          : escalation === 'L4'
          ? 'Critical (L4)'
          : 'Not available'
      }
    >
      <CustomBox escalation={escalation}>
        <CustomText variant='h2'>{escalation}</CustomText>
      </CustomBox>
    </Tooltip>
  );
};

export default EscalationBox;
