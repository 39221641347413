import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Box } from '@material-ui/core';
import AuthContext from '../context/auth/authContext';

const NotFound = ({ location }) => {
  const authContext = useContext(AuthContext);

  const { privilege } = authContext;

  return (
    <Container
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <Box>
        <Typography>Oops! That page can’t be found.</Typography>
        <Typography>
          It looks like nothing was found at this location. Click below to
          return to main page.
        </Typography>
        <Link to={privilege === 0 ? '/dashboard' : '/admin'}>Return back</Link>
      </Box>
    </Container>
  );
};

export default NotFound;
