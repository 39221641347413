import React, { useContext } from 'react';
import AuthContext from '../context/auth/authContext';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Login from '../components/auth/Login';
import NotFound from '../pages/NotFound';
import Roles from '../utils/AuthRoles';
import Dashboard from '../pages/Dashboard';
import Reclamations from '../pages/Reclamations';
import Performance from '../pages/Performance';
import { AdminRoutes, UserRoutes } from './RoutePaths';
import AdminDashboard from '../pages/AdminDashboard';
import Navbar from '../components/layout/Navbar';
import AdminNavbar from '../components/layout/AdminNavbar';
import Footer from '../components/layout/Footer';

const Routes = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, privilege } = authContext;

  return (
    <Router>
      {isAuthenticated && privilege === 0 ? (
        <Navbar />
      ) : isAuthenticated && privilege === 1 ? (
        <AdminNavbar />
      ) : null}
      <Switch>
        <Route exact path="/" component={Login} />
        <AuthRoute
          path={UserRoutes.Dashboard}
          component={Dashboard}
          requiredRoles={[Roles.User]}
        />
        <AuthRoute
          path={UserRoutes.Reclamations}
          component={Reclamations}
          requiredRoles={[Roles.User]}
        />
        <AuthRoute
          path={UserRoutes.Performance}
          component={Performance}
          requiredRoles={[Roles.User]}
        />
        <AuthRoute
          path={AdminRoutes.AdminDashboard}
          component={AdminDashboard}
          requiredRoles={[Roles.Admin]}
        />
        <Route component={NotFound} />
      </Switch>
      {isAuthenticated && <Footer />}
    </Router>
  );
};

export default Routes;
