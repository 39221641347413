import axios from "axios";

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["x-api-key"] = process.env.REACT_APP_API_KEY;
  } else {
    delete axios.headers.common["Authorization"];
    delete axios.defaults.headers.common["x-api-key"];
  }
};

export default setAuthToken;
