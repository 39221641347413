import decode from 'jwt-decode';

// Function to check if token is valid
const checkPrivilege = token => {
  const decoded = decode(token);
  if (decoded.user[0].privileges === 0) {
    return 0;
  } else if (decoded.user[0].privileges === 1) {
    return 1;
  }
};

export default checkPrivilege;
