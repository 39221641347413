import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from '../context/global/globalContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CloseRounded from '@material-ui/icons/CloseRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: '0 10px',
    fontSize: '1.25rem',
    color: '#fff'
  },
  icon: {
    color: theme.palette.primary.main
  },
  button: {
    margin: theme.spacing(1)
  },
  fabButton: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submitButton: {
    marginTop: theme.spacing(3)
  },
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  note: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  checkIcon: {
    color: theme.palette.green
  }
}));

const getSteps = () => {
  return [
    'Interim Containment Action',
    'Corrective And Preventive Action',
    'Summary'
  ];
};

const getStepContent = (stepIndex, supplierXd, classes, handleChange) => {
  const stepInstructions = {
    x2D:
      'The problem description is the expression of the difference between what should have happened and what actually happened. Describe the internal/external problem by identifying ‘what is wrong with what’ and detail the problem in quantifiable terms. DO NOT REPEAT our description in claim report.',
    x3D:
      'Define and implement the Interim Containment Action (ICA) to isolate the effects of the problem from your production. Validate the effectiveness of the containment actions. Describe which activities has been taken place on which production area/line/machine.',
    x4D:
      'There might be some defected pieces in raw material, semi-finished or finished product stock. Some defected pieces might be on the way to us. Define and implement the actions for stocks and pipeline. Tell us the actions and the result of them.',
    x5D:
      'Identify all applicable causes that could explain why the problem has occurred. Also identify why the problem was not noticed at the time it occurred. All causes shall be verified or proved. Corrective and Preventive Actions can’t be defined if root cause is unknown.',
    x6D:
      'Define and implement the best corrective actions. Also, validate corrective actions with empirical evidence of improvement',
    x7D:
      'Define and implement the best proactive actions to prevent further reoccurrence of such non-conformities or other undesirable situations. Modify the management systems, practices, and procedures to prevent recurrence of this and similar problems. See difference between PA and CA.'
  };

  switch (stepIndex) {
    case 0:
      return (
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='stretch'
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              label='Define and describe the problem'
              helperText={`Character count: ${supplierXd.x2D.length}`}
              name='x2D'
              disabled={supplierXd.x4DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x2D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x2D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x2D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Interim Containment Actions (ICA)'
              helperText={`Character count: ${supplierXd.x3D.length}`}
              name='x3D'
              disabled={supplierXd.x4DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x3D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x3D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x3D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Actions on pieces in stock and pipeline'
              helperText={`Character count: ${supplierXd.x4D.length}`}
              name='x4D'
              disabled={supplierXd.x4DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x4D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x4D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x4D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='stretch'
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              label='Root Cause Analysis (RCA)'
              helperText={`Character count: ${supplierXd.x5D.length}`}
              name='x5D'
              disabled={supplierXd.x7DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x5D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x5D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x5D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Corrective Actions (CA)'
              helperText={`Character count: ${supplierXd.x6D.length}`}
              name='x6D'
              disabled={supplierXd.x7DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x6D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x6D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x6D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Preventive Actions (PA)'
              helperText={`Character count: ${supplierXd.x7D.length}`}
              name='x7D'
              disabled={supplierXd.x7DCompleted === null ? false : true}
              fullWidth
              required
              className={classes.textInput}
              variant='outlined'
              multiline={true}
              rows={5}
              onChange={handleChange}
              value={supplierXd.x7D}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={stepInstructions.x7D}
                      arrow
                      placement='left'
                    >
                      {supplierXd.x7D.length >= 50 ? (
                        <CheckCircleOutlineRoundedIcon
                          className={classes.checkIcon}
                        />
                      ) : (
                        <HelpOutline color='secondary' />
                      )}
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <List>
          <ListItem>
            <ListItemText
              primary='Define and describe the problem'
              secondary={supplierXd.x2D}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Interim Containment Actions (ICA)'
              secondary={supplierXd.x3D}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Actions on pieces in stock and pipeline'
              secondary={supplierXd.x4D}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Root Cause Analysis (RCA)'
              secondary={supplierXd.x5D}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Corrective Actions (CA)'
              secondary={supplierXd.x6D}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Preventive Actions (PA)'
              secondary={supplierXd.x7D}
            />
          </ListItem>
        </List>
      );
    default:
      return 'Unknown stepIndex';
  }
};

const ReclamationsForm = ({ open, onClose, supplierxD }) => {
  const globalContext = useContext(GlobalContext);
  const { updateReclamations, uploading } = globalContext;

  const classes = useStyles();

  const [supplierXd, setSupplierXd] = useState({
    reclamationid: '',
    status: '',
    x2D: '',
    x3D: '',
    x4D: '',
    x4DCompleted: null,
    x5D: '',
    x6D: '',
    x7D: '',
    x7DCompleted: null
  });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    setSupplierXd(supplierxD);
    handleActiveStep(supplierxD);
  }, [supplierxD, open]);

  useEffect(() => {
    if (uploading) {
      handleClose();
    }
    //eslint-disable-next-line
  }, [uploading]);

  const handleChange = e => {
    setSupplierXd({ ...supplierXd, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleActiveStep = supplierxD => {
    supplierxD.x4DCompleted === null && supplierxD.x7DCompleted === null
      ? setActiveStep(0)
      : supplierxD.x4DCompleted !== null && supplierxD.x7DCompleted === null
      ? setActiveStep(1)
      : setActiveStep(2);
  };

  const closeConfirmationAlert = () => {
    setOpenConfirmation(false);
  };

  const handleClose = async () => {
    onClose();
    setActiveStep(0);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setOpenConfirmation(false);
    updateReclamations(supplierXd);
  };

  const ConfirmationDialog = () => (
    <Dialog
      open={openConfirmation}
      onClose={closeConfirmationAlert}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Submit Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          If every text field contains over 50 characters, you are not able to
          modify your answers afterwards. Are you sure you want to submit your
          answers?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeConfirmationAlert} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      transitionDuration={500}
      disableBackdropClick
      scroll='body'
    >
      <DialogTitle disableTypography className={classes.dialog}>
        <Typography variant='h5'>
          Claim ID {supplierxD.reclamationId}
        </Typography>
        <IconButton
          color='primary'
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Alert severity='info' className={classes.note}>
            <Typography variant='body2'>
              <b>NOTE</b> - Each text field has to contain over 50 characters to
              proceed to the next step.
            </Typography>
          </Alert>
        </div>
        {getStepContent(activeStep, supplierXd, classes, handleChange)}
        {activeStep < 2 && (
          <Button
            onClick={
              activeStep === 0 &&
              supplierXd.x2D.length >= 50 &&
              supplierXd.x3D.length >= 50 &&
              supplierXd.x4D.length >= 50
                ? () => setOpenConfirmation(true)
                : activeStep === 1 &&
                  supplierXd.x5D.length >= 50 &&
                  supplierXd.x6D.length >= 50 &&
                  supplierXd.x7D.length >= 50
                ? () => setOpenConfirmation(true)
                : handleSubmit
            }
            color='secondary'
            variant='contained'
            className={classes.submitButton}
            fullWidth
            disabled={
              supplierXd.x4DCompleted !== null && activeStep === 0
                ? true
                : supplierXd.x7DCompleted !== null && activeStep === 1
                ? true
                : false
            }
          >
            {uploading ? (
              <Fade in={uploading ? true : false} timeout={500}>
                <CircularProgress size={24} className={classes.progress} />
              </Fade>
            ) : activeStep === 0 &&
              supplierXd.x2D.length >= 50 &&
              supplierXd.x3D.length >= 50 &&
              supplierXd.x4D.length >= 50 ? (
              'Submit first step'
            ) : activeStep === 1 &&
              supplierXd.x5D.length >= 50 &&
              supplierXd.x6D.length >= 50 &&
              supplierXd.x7D.length >= 50 ? (
              'Submit second step'
            ) : (
              'Save changes'
            )}
          </Button>
        )}
        <ConfirmationDialog />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant='text'
          color='primary'
          disabled={activeStep === 0}
          onClick={handleBack}
          startIcon={<NavigateBeforeRoundedIcon />}
          style={{ visibility: activeStep > 0 ? 'visible' : 'hidden' }}
        >
          Back
        </Button>
        <Button
          variant='text'
          color='primary'
          onClick={activeStep === 2 ? onClose : handleNext}
          disabled={
            supplierXd.x4DCompleted === null && activeStep === 0
              ? true
              : supplierXd.x7DCompleted === null && activeStep === 1
              ? true
              : false
          }
          endIcon={
            activeStep === steps.length - 1 ? null : <NavigateNextRoundedIcon />
          }
        >
          {activeStep === steps.length - 1 ? 'Close ' : 'Next '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReclamationsForm;
