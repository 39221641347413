import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '104px',
    marginBottom: '32px'
  },
  paper: {
    padding: theme.spacing(2)
  },
  table: {
    minWidth: 150
  },
  gridItem: {
    marginTop: theme.spacing(2)
  }
}));

const ppmscore = [
  { ppm: '≤ 500', score: 30 },
  { ppm: '≤ 2500', score: 20 },
  { ppm: '≤ 5000', score: 10 },
  { ppm: '≤ 10000', score: 5 },
  { ppm: '> 10000', score: 0 }
];

const claimscore = [
  { claim: 0, score: 50 },
  { claim: 1, score: 25 },
  { claim: '≤ 4', score: 15 },
  { claim: '≤ 6', score: 5 },
  { claim: '> 6', score: 0 }
];
const ncpscore = [
  { ncp: 0, score: 20 },
  { ncp: 1, score: 15 },
  { ncp: '≤ 5', score: 10 },
  { ncp: '≤ 10', score: 5 },
  { ncp: '> 10', score: 0 }
];

const addscore = [
  { add: 0, score: 20 },
  { add: '≤ 2', score: 15 },
  { add: '≤ 5', score: 10 },
  { add: '≤ 7', score: 5 },
  { add: '> 7', score: 0 }
];

const Performance = () => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
    return () => {
      setChecked(false);
    };
    //eslint-disable-next-line
  }, [setChecked]);

  return (
    <>
      <Fade in={checked} direction='right'>
        <Container maxWidth='lg' className={classes.container}>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='h6' component='h2'>
                SCORECARD PRESENTATION
              </Typography>
              <br />
              <Typography variant='body1'>
                The scorecard is a tool for monitoring the performance of a
                supplier. It should be used by suppliers to take proactive
                actions on their production quality and delivery precision. This
                scorecard helps in monitoring the quality and delivery trends
                and can be an ALERT in the case of LOW PERFORMANCE. <br />{' '}
                <br /> Skoda Transtech maintains a scorecard of the quality and
                delivery performance for each supplier that delivers parts to
                Skoda Transtech, Otanmäki facility. The measurements on this
                scorecard are regularly reviewed to track supplier performance
                and identify negative trends. This information is available for
                supplier review over the supplier portal. It is recommended that
                suppliers review this information on a regular basis. Regular
                review of their performance data allows suppliers to take action
                to address problems and trends before Skoda Transtech is
                required to take action with the supplier. The scorecard is
                updated at the end of each quarter (March, June, September,
                December). <br />
                <br /> The scorecard shows information for a full quarter. The
                last 5 scorecards are available and status of running quarter on
                the supplier portal.
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Typography variant='h6' component='h2'>
                QUALITY PERFORMANCE
              </Typography>
              <br />
              <Typography variant='body1'>
                The Quality Performance (QP) is a key indicator of supplier
                performance that reflects the impact that delivery of
                nonconforming parts have on Skoda Transtech. The QP is a tool to
                provide an objective method for measuring supplier quality
                performance. There are 3 measures, which are compared to
                determine Quality Performance score: Parts per Million (PPM),
                Claims and Non-Conforming Parts (NCP). The maximum score for
                Quality Performance is 100. Parts per Million (PPM) can be 30,
                Claims can be 50 and Non-Conforming Parts (NCP) 20. The scores
                are given according the following tables.
              </Typography>
            </Grid>
            <Grid
              item
              container
              justify='space-evenly'
              spacing={3}
              className={classes.gridItem}
            >
              <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.paper}>
                  <Table className={classes.table} size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          <Typography variant='h5'>PPM</Typography>
                        </TableCell>
                        <TableCell align='center'>
                          <Typography variant='h5'>SCORE</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ppmscore.map(row => (
                        <TableRow key={row.ppm}>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.ppm}</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.score}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.paper}>
                  <Table className={classes.table} size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          <Typography variant='h5'>CLAIMS</Typography>
                        </TableCell>
                        <TableCell align='center'>
                          <Typography variant='h5'>SCORE</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {claimscore.map(row => (
                        <TableRow key={row.claim}>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.claim}</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.score}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.paper}>
                  <Table className={classes.table} size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          <Typography variant='h5'>NCP</Typography>
                        </TableCell>
                        <TableCell align='center'>
                          <Typography variant='h5'>SCORE</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ncpscore.map(row => (
                        <TableRow key={row.ncp}>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.ncp}</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant='h6'>{row.score}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant='body1'>
                Following example shows how measures are calculated. If supplier
                delivers 657 delivery lines and 1.422 parts in quarter. Skoda
                Transtech writes 3 claim reports which includes 5 non-conforming
                parts.
              </Typography>
              <ul className={classes.list}>
                <li>
                  <Typography variant='body1'>
                    PPM: 5/1.422*1.000.000 is 3.516 ppm. The score is 10
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Claims: 3 claims. The score is 15
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    NCP: 5 non-conforming parts.
                  </Typography>
                </li>
              </ul>
              <Typography variant='body1'>
                The score is 10 The score of Quality Performance (QP) is 35 (10
                + 15 + 10)
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant='h6' component='h2'>
                DELIVERY PRECISION
              </Typography>
              <br />
              <Typography variant='body1'>
                The Delivery Precision (DP) is another key indicator of supplier
                performance. The supplier’s delivery performance is based on
                timely delivery and delivery of correct quantities. The days of
                delayed deliver y lines are also measured. There are two
                measures , which are compared to determine the Delivery
                Precision (DP) score : On Time Delivery (OTD) and Average Days
                Delayed (ADD). The maximum score for Delivery Precision is 100.
                OTD can be 80 and ADD can be 20 . The score of OTD is always 80%
                of current OTD and ADD score is given according the following
                table.
              </Typography>
              <Grid item container justify='center'>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.gridItem}
                  style={{ marginBottom: '32px' }}
                >
                  <Card>
                    <Table className={classes.table} size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>
                            <Typography variant='h5'>ADD</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography variant='h5'>SCORE</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addscore.map(row => (
                          <TableRow key={row.add}>
                            <TableCell align='center'>
                              <Typography variant='h6'>{row.add}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                              <Typography variant='h6'>{row.score}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>
              <Typography>
                Following example shows how measures are calculated. If supplier
                delivers 657 delivery lines in quarter . 68 lines of them are
                delayed on average 2,3 days. The Delivery Precision (DP) score
                will be 81.
                <br />
              </Typography>
              <ul>
                <li>
                  <Typography variant='body1'>
                    OTD: (657 - 68)/657*80 = 71
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    ADD: according the table the score is 10.
                  </Typography>
                </li>
              </ul>
              <Typography variant='body1'>
                The score of Delivery Precision is 81 (71 + 10)
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant='h6' component='h2'>
                SUPPLIER STATUS
              </Typography>
              <br />
              <Typography variant='body1'>
                There are 3 different status: Excellent (E ), Passed (P) and
                Fail (F). The maximum score is 200 which is sum of two key
                indicators; Quality Performance (100) and Delivery Precision
                (100). When the total score is equal or over 190, the status is
                Excellent (E). If the total score is 100 or less, the status is
                Fail (F). Total score according previous example s is 116 (35 +
                81). The supplier status is Passed (P) .
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant='h6' component='h2'>
                LOW PERFORMING SUPPLIER
              </Typography>
              <br />
              <Typography>
                The goal for Low Performing Supplier is to drive the
                responsibility to monitor and improve their quality and delivery
                performance. The Low Per forming Supplier need to identify,
                initiate and drive improvement activities. When the status of
                scorecard is Fail (F) , it indicates the beginning of a negative
                performance trend or significant abnormality, the supplier case
                is elevat ed into Escalation Process for detailed analysis and
                action. <br />
                <br /> Supplier improvement activities will be initiated and
                managed through a four - stage Escalation process. Each stage
                will have identified actions . The exit of Escalation Process is
                based on results a nd process improvements. The Low Performing
                Supplier exit s from Escalation Process when the status of
                scorecard is Passed (P) or Excellent (E). The level of
                escalation process is L0 when supplier status is Passed (P) or
                Excellent (E) and there are no further actions required.
                Supplier status Fail (F) elevate escalation process and the
                first level is L1. There is need for further actions according
                the following table. If supplier continues low performing on
                next quarter, escalation process level will be L2. The number of
                levels tell how many quarters supplier has performed low.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Card>
                <Table className={classes.table} size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align='center'
                        style={{
                          width: '25%',
                          background: '#FFCC00',
                          color: '#FFFFFF'
                        }}
                      >
                        <Typography variant='h5'>L1</Typography>
                        <Typography variant='body1'>(Analyze)</Typography>
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{
                          width: '25%',
                          background: '#FF6600',
                          color: '#FFFFFF'
                        }}
                      >
                        <Typography variant='h5'>L2</Typography>
                        <Typography variant='body1'>(Alert)</Typography>
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{
                          width: '25%',
                          background: '#FF0000',
                          color: '#FFFFFF'
                        }}
                      >
                        <Typography variant='h5'>L3</Typography>
                        <Typography variant='body1'>(Alarm)</Typography>
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{
                          width: '25%',
                          background: '#000000',
                          color: '#FFFFFF'
                        }}
                      >
                        <Typography variant='h6'>L4</Typography>
                        <Typography variant='body1'>(Critical)</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align='left'>
                        <ul>
                          <li>
                            <Typography variant='body2'>
                              Supplier’s visit at Transtech
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Attend to 8D Training
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              8D - Quality Performance
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              8D - Delivery Precisio
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Action plan (weekly reporting)
                            </Typography>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align='left'>
                        <ul>
                          <li>
                            <Typography variant='body2'>
                              Previous requirement
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Process audit at supplier (Delivery, Production,
                              Purchase, Finance)
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Action plan from deviation in process audit{' '}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Weekly Reporting of action plan
                            </Typography>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align='left'>
                        <ul>
                          <li>
                            <Typography variant='body2'>
                              Previous requirement
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Supplier MD’s Visit on Skoda Transtech
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              New business on hold
                            </Typography>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell align='left'>
                        <ul>
                          <li>
                            <Typography variant='body2'>
                              Previous requirement
                            </Typography>
                          </li>
                          <li>
                            <Typography variant='body2'>
                              Initate supplier change
                            </Typography>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </>
  );
};

export default Performance;
