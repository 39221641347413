import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/auth/authContext';

const AuthRoute = ({ component: Component, path, requiredRoles, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, privilege } = authContext;
  const hasToken = sessionStorage.getItem('token');
  const userHasRequiredRole = requiredRoles.includes(privilege);

  return (
    <Route
      {...rest}
      path={path}
      render={props =>
        isAuthenticated && hasToken && userHasRequiredRole ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthRoute;
