import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';
import { FaReact } from 'react-icons/fa';
import { version } from '../../../package.json';

const useStyles = makeStyles(theme => ({
  footer: {
    height: '5vh',
    textAlign: 'center',
    margin: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: '#FAFAFA'
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth='xl'>
        <Typography variant='body1' color='textPrimary'>
          Built with <FaReact /> by{' '}
          <Link color='primary' href='https://kodelabs.io' target='_blank'>
            Kode Labs.
          </Link>
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {version}
        </Typography>
      </Container>
    </footer>
  );
}
