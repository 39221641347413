import React from 'react';
import './App.css';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import AuthState from './context/auth/AuthState';
import GlobalState from './context/global/GlobalState';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import AdminState from './context/admin/AdminState';
import Routes from './routes/Routes';

const App = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate={true}
            autoHideDuration={4000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <AuthState>
              <AdminState>
                <GlobalState>
                  <Routes />
                </GlobalState>
              </AdminState>
            </AuthState>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
