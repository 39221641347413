import React, { useEffect, useContext, useState } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GlobalContext from '../context/global/globalContext';

const SupplierScoringCharts = () => {
  const useStyles = makeStyles(theme => ({
    container: {
      marginTop: theme.spacing(3),
      padding: 0
    }
  }));

  const globalContext = useContext(GlobalContext);
  const { supplierScoringsToCharts } = globalContext;

  const [quartersArray, setQuartersArray] = useState([]);
  const [scoresArray, setScoresArray] = useState([]);
  const [qpScoresArray, setQpScoresArray] = useState([]);
  const [dpScoresArray, setDpScoresArray] = useState([]);

  const scoresData = {
    labels: quartersArray,
    datasets: [
      {
        data: scoresArray,
        backgroundColor: 'rgba(0, 63, 126, 1)',
        barThickness: 40
      }
    ]
  };

  const dpScoresData = {
    labels: quartersArray,
    datasets: [
      {
        data: dpScoresArray,
        backgroundColor: 'rgba(0, 63, 126, 1)',
        barThickness: 40
      }
    ]
  };

  const qpScoresData = {
    labels: quartersArray,
    datasets: [
      {
        data: qpScoresArray,
        backgroundColor: 'rgba(0, 63, 126, 1)',
        barThickness: 40
      }
    ]
  };

  const option1 = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100
            // stepSize: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          }
        }
      ]
    },
    legend: {
      display: false
    }
  };

  const option2 = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 200
            // stepSize: 40
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          }
        }
      ]
    },
    legend: {
      display: false
    }
  };

  const setArrays = async () => {
    await supplierScoringsToCharts.map(data => {
      return (
        setQuartersArray(quartersArray => [...quartersArray, data.QUARTER]),
        setDpScoresArray(dpScoresArray => [...dpScoresArray, data.DP_SCORE]),
        setQpScoresArray(qpScoresArray => [...qpScoresArray, data.QP_SCORE]),
        setScoresArray(scoresArray => [...scoresArray, data.SCORE])
      );
    });
  };

  useEffect(() => {
    if (
      (quartersArray.length ||
        dpScoresArray.length ||
        qpScoresArray.length ||
        scoresArray.length) === 0
    ) {
      setArrays();
    }
    //eslint-disable-next-line
  }, [supplierScoringsToCharts]);

  defaults.global.defaultFontFamily = 'Klavika';

  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card>
            <CardHeader
              title="Quality Performance"
              subheader="Scores from past quarters"
            />

            <Bar data={qpScoresData} options={option1} />
          </Card>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card>
            <CardHeader
              title="Delivery Precision"
              subheader="Scores from past quarters"
            />
            <Bar data={dpScoresData} options={option1} />
          </Card>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card>
            <CardHeader
              title="Total Score"
              subheader="Scores from past quarters"
            />
            <Bar data={scoresData} options={option2} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupplierScoringCharts;
