import {
  GET_QUARTERS,
  GET_SCORINGLINES,
  GET_SUPPLIERSCORING,
  GET_SUPPLIERSCORINGSTOCHARTS,
  SET_LOADING,
  END_LOADING,
  GET_RECLAMATIONS,
  UPDATE_RECLAMATIONS,
  SET_UPLOADING,
  CLEAR_STATE,
  SET_CURRENTQUARTER
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_QUARTERS:
      return {
        ...state,
        quarters: action.payload,
        currentQuarter: action.payload[action.payload.length - 2]
      };
    case SET_CURRENTQUARTER:
      return {
        ...state,
        currentQuarter: action.payload
      };
    case GET_SCORINGLINES:
      return {
        ...state,
        scoringLines: action.payload
      };
    case GET_SUPPLIERSCORING:
      return {
        ...state,
        supplierScorings: action.payload
      };
    case GET_SUPPLIERSCORINGSTOCHARTS:
      return {
        ...state,
        supplierScoringsToCharts: action.payload
      };
    case GET_RECLAMATIONS:
      return {
        ...state,
        reclamations: action.payload,
        loading: false
      };
    case UPDATE_RECLAMATIONS:
      return {
        ...state,
        reclamations: state.reclamations.map(reclamation =>
          reclamation.SupplierxD.reclamationId === action.payload.reclamationId
            ? { ...reclamation, SupplierxD: action.payload }
            : reclamation
        ),
        uploading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case END_LOADING:
      return {
        ...state,
        loading: false
      };
    case SET_UPLOADING:
      return {
        ...state,
        uploading: true
      };
    case CLEAR_STATE:
      return {
        quarters: [],
        lastFullQuarter: '',
        scoringLines: [],
        supplierScorings: [],
        supplierScoringsToCharts: [],
        reclamations: [],
        loading: false,
        uploading: false
      };
    default:
      return state;
  }
};
