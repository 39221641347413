import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../context/global/globalContext';
import SupplierScoring from '../components/SupplierScoring';
import SupplierScoringCharts from '../components/SupplierScoringCharts';
import ScoringLines from '../components/ScoringLines';
import Loading from '../components/layout/Loading';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '104px',
    marginBottom: '32px'
  }
}));

const Dashboard = () => {
  const globalContext = useContext(GlobalContext);

  const {
    loading,
    supplierScorings,
    supplierScoringsToCharts,
    scoringLines,
    getSupplierData,
    quarters
  } = globalContext;

  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
    return () => {
      setChecked(false);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      quarters.length === 0 ||
      supplierScorings.length === 0 ||
      scoringLines.length === 0 ||
      supplierScoringsToCharts.length === 0
    ) {
      getSupplierData();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Fade in={checked} timeout={500}>
      <Container maxWidth='xl' className={classes.container}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <SupplierScoring supplierScorings={supplierScorings} />
            <SupplierScoringCharts />
            <ScoringLines />
          </>
        )}
      </Container>
    </Fade>
  );
};

export default Dashboard;
