import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Button,
  Card,
  TextField,
  Typography,
  makeStyles,
  Container,
  Fade,
  Link,
  CircularProgress
} from '@material-ui/core';
import SkodaLogo from '../../img/logos/SKODA_logo1_blue.png';
import JokeriVaunu from '../../img/jokeri_vaunu_background.jpg';
import { FaReact } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  containerBackground: {
    backgroundImage: `url(${JokeriVaunu})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    background: '#FFFFFFC4',
    width: '380px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
  },
  logo: {
    width: '100%',
    margin: theme.spacing(1)
  },
  form: {
    width: '85%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 4),
    backgroundImage: 'linear-gradient(135deg, #003f7e, #0096d6, #003f7e);'
  },
  footerText: {
    margin: theme.spacing(2)
  },
  progress: {
    color: '#fff'
  }
}));

const Login = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Auth context
  const authContext = useContext(AuthContext);
  const { login, privilege, loading } = authContext;

  const [user, setUser] = useState({
    userid: '',
    password: ''
  });

  const [checked, setChecked] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.token && privilege === 0) {
      history.push('/dashboard');
    } else if (sessionStorage.token && privilege === 1) {
      history.push('/admin-dashboard');
    }
    setChecked(true);

    return () => {
      setChecked(false);
    };

    //eslint-disable-next-line
  }, [sessionStorage.token, props.history, setChecked]);

  const { userid, password } = user;

  const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (userid === '' || password === '') {
      enqueueSnackbar('Please enter all fields!', { variant: 'error' });
    } else {
      login({ userid, password });
    }
  };

  const classes = useStyles();

  return (
    <>
      <Fade in={checked} timeout={500}>
        <Container maxWidth='xl' className={classes.containerBackground}>
          <Fade in={checked} direction='up' timeout={1000}>
            <Card className={classes.card}>
              <img alt='skodaLogo' src={SkodaLogo} className={classes.logo} />
              <Typography component='h1' variant='h4' color='primary'>
                Supplier Portal
              </Typography>
              <form className={classes.form} noValidate onSubmit={onSubmit}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='userid'
                  label='Company ID'
                  name='userid'
                  autoFocus
                  value={userid}
                  onChange={onChange}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  value={password}
                  onChange={onChange}
                />

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  {loading ? (
                    <Fade in={loading ? true : false} timeout={500}>
                      <CircularProgress
                        size={24}
                        className={classes.progress}
                      />
                    </Fade>
                  ) : (
                    'LOGIN'
                  )}
                </Button>
              </form>
              <Typography
                variant='body1'
                color='textSecondary'
                className={classes.footerText}
              >
                Built with <FaReact /> by{' '}
                <Link
                  color='primary'
                  href='https://kodelabs.io'
                  target='_blank'
                >
                  Kode Labs.
                </Link>
              </Typography>
            </Card>
          </Fade>
        </Container>
      </Fade>
    </>
  );
};

export default Login;
