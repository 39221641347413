import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import AuthContext from '../auth/authContext';
import GlobalContext from './globalContext';
import GlobalReducer from './globalReducer';
import {
  GET_QUARTERS,
  SET_CURRENTQUARTER,
  GET_SCORINGLINES,
  GET_SUPPLIERSCORING,
  GET_SUPPLIERSCORINGSTOCHARTS,
  SET_LOADING,
  GET_RECLAMATIONS,
  UPDATE_RECLAMATIONS,
  SET_UPLOADING,
  CLEAR_STATE,
  END_LOADING
} from '../types';
import setAuthToken from '../../utils/setAuthToken';

const GlobalState = props => {
  const initialState = {
    quarters: [],
    currentQuarter: '',
    scoringLines: [],
    supplierScorings: [],
    supplierScoringsToCharts: [],
    reclamations: [],
    loading: false,
    uploading: false,
    reclamationUpdated: false
  };

  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  const authContext = useContext(AuthContext);

  // Destructure logout function from authContext
  const { redirect, userid, renewToken } = authContext;

  const { enqueueSnackbar } = useSnackbar();
  // Get quarters
  const getQuarters = async () => {
    if (initialState.quarters.length === 0) {
      setLoading();
      await renewToken();
      if (sessionStorage.token) {
        setAuthToken(sessionStorage.getItem('token'));
        try {
          const res = await axios.get(
            process.env.REACT_APP_API + `/quarters/${userid}`
          );

          dispatch({
            type: GET_QUARTERS,
            payload: res.data
          });

          return res.data[res.data.length - 2];
        } catch (error) {
          if (error.response.status === 401) {
            enqueueSnackbar('Session expired, logging out now.', {
              variant: 'warning'
            });
            redirect();
          }
        }
      }
    }
  };
  // Get scoringlines
  const getScoringLines = async quarter => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.getItem('token'));
      try {
        const res = await axios.get(
          process.env.REACT_APP_API +
            `/scoringlines/${userid}?quarter=${quarter}`
        );
        dispatch({
          type: GET_SCORINGLINES,
          payload: res.data
        });
      } catch (error) {
        if (error.response.status === 401) {
          enqueueSnackbar('Session expired, logging out now.', {
            variant: 'warning'
          });
          redirect();
        }
      }
    }
  };

  // Get supplierscoring
  const getSupplierScoring = async quarter => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.getItem('token'));
      try {
        const res = await axios.get(
          process.env.REACT_APP_API +
            `/supplierscoring/${userid}?quarter=${quarter}`
        );

        dispatch({
          type: GET_SUPPLIERSCORING,
          payload: res.data
        });
      } catch (error) {
        if (error.response.status === 401) {
          enqueueSnackbar('Session expired, logging out now.', {
            variant: 'warning'
          });
          redirect();
        }
      }
    }
  };

  // Get supplierscorings to charts
  const getSupplierScoringsToCharts = async () => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.getItem('token'));
      try {
        const res = await axios.get(
          process.env.REACT_APP_API + `/supplierscorings/${userid}`
        );

        dispatch({
          type: GET_SUPPLIERSCORINGSTOCHARTS,
          payload: res.data
        });
      } catch (error) {
        if (error.response.status === 401) {
          enqueueSnackbar('Session expired, logging out now.', {
            variant: 'warning'
          });
          redirect();
        }
      }
    }
  };

  const getSupplierData = async () => {
    setLoading();
    await renewToken();
    const quarter = await getQuarters();
    try {
      if (initialState.quarters.length === 0) {
        await getSupplierScoringsToCharts();
      }
      await getSupplierScoring(quarter);
      await getScoringLines(quarter);
    } catch (e) {
      console.log(e);
    }
    endLoading();
  };

  const getReclamations = async () => {
    setLoading();
    await renewToken();
    setAuthToken(sessionStorage.getItem('token'));
    try {
      const res = await axios.get(
        process.env.REACT_APP_API + `/reclamations/${userid}`
      );
      dispatch({
        type: GET_RECLAMATIONS,
        payload: res.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateReclamations = async supplierxd => {
    setUploading();
    await renewToken();
    setAuthToken(sessionStorage.getItem('token'));
    try {
      const res = await axios.put(
        process.env.REACT_APP_API + `/supplierxd/${userid}`,
        supplierxd
      );
      dispatch({
        type: UPDATE_RECLAMATIONS,
        payload: res.data
      });
      enqueueSnackbar(
        `Claim ${supplierxd.reclamationId} updated successfully.`,
        {
          variant: 'success'
        }
      );
    } catch (error) {
      enqueueSnackbar(`Failed to update claim ${supplierxd.reclamationId}.`, {
        variant: 'error'
      });
      console.log(error);
    }
  };

  const setCurrentQuarter = quarter => {
    dispatch({
      type: SET_CURRENTQUARTER,
      payload: quarter
    });
  };

  const setLoading = () => {
    dispatch({
      type: SET_LOADING
    });
  };

  const endLoading = () => {
    dispatch({
      type: END_LOADING
    });
  };

  const setUploading = () => {
    dispatch({
      type: SET_UPLOADING
    });
  };

  const clearState = () => {
    dispatch({
      type: CLEAR_STATE
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        quarters: state.quarters,
        currentQuarter: state.currentQuarter,
        loading: state.loading,
        uploading: state.uploading,
        scoringLines: state.scoringLines,
        supplierScorings: state.supplierScorings,
        supplierScoringsToCharts: state.supplierScoringsToCharts,
        reclamations: state.reclamations,
        getQuarters,
        setCurrentQuarter,
        getSupplierData,
        getScoringLines,
        getSupplierScoring,
        setLoading,
        getReclamations,
        updateReclamations,
        clearState
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
