import React, { useEffect, useState } from 'react';
import { CircularProgress, Fade } from '@material-ui/core';

const Loading = () => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
    return () => {
      setChecked(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fade in={checked} timeout={500}>
      <div
        style={{
          height: '95vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </div>
    </Fade>
  );
};

export default Loading;
