import React from 'react';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const WaitingIcon = styled(RemoveCircleOutlineOutlinedIcon)`
  color: #fff;
`;
const InProgressIcon = styled(ErrorOutlineOutlinedIcon)`
  color: #fff;
`;
const CompletedIcon = styled(CheckCircleOutlineOutlinedIcon)`
  color: #fff;
`;

export const CustomChip = styled(Chip)`
  color: #fff;
  background-color: ${props =>
    props.status === 'R'
      ? '#F00000'
      : props.status === 'Y'
      ? '#FFCC00'
      : props.status === 'G'
      ? '#00B050'
      : null};
`;

const StatusChipSC = ({ status }) => {
  return (
    <CustomChip
      status={status}
      size='small'
      label={
        status === 'R'
          ? 'Unresolved'
          : status === 'Y'
          ? 'Pending'
          : status === 'G'
          ? 'Resolved'
          : null
      }
      icon={
        status === 'R' ? (
          <WaitingIcon />
        ) : status === 'Y' ? (
          <InProgressIcon />
        ) : status === 'G' ? (
          <CompletedIcon />
        ) : null
      }
    />
  );
};

export default StatusChipSC;
