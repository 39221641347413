import React from 'react';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StatusBoxSC from './layout/StatusBoxSC';
import TrafficLightsBoxSC from './layout/TrafficLightsBoxSC';
import EscalationBoxSC from './layout/EscalationBoxSC';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  card: {
    height: '100%',
  },
}));

const SupplierScoring = ({ supplierScorings }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      {supplierScorings.map((supplierScoring, i) => (
        <Grid
          key={i}
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={4}
          style={{ minHeight: '20vh' }}
        >
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent style={{ display: 'block' }}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="h5" align="left">
                      Status
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      color="textSecondary"
                    >
                      Supplier Status
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StatusBoxSC status={supplierScoring.STATUS} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Card className={classes.card}>
              {supplierScoring.ESCALATION !== '' ? (
                <CardContent style={{ display: 'block' }}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="h5" align="left">
                        Escalation
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        Escalation Status
                      </Typography>
                    </Grid>
                    <Grid item>
                      <EscalationBoxSC
                        escalation={supplierScoring.ESCALATION}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              ) : supplierScoring.DELCOST !== null ||
                supplierScoring.CLAIMCOST !== null ? (
                <CardContent>
                  <Typography variant="h5" align="left">
                    Escalation
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Escalation Status
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="h6">
                      Quality Cost: {supplierScoring.CLAIMCOST}€
                    </Typography>
                    <Typography variant="h6">
                      Delivery Cost: {supplierScoring.DELCOST}€
                    </Typography>
                  </Box>
                </CardContent>
              ) : (
                <CardContent>
                  <Typography variant="h5" align="left">
                    Escalation
                  </Typography>
                  <Typography variant="body1">
                    Escalation level is calculated ONLY for last closed quarter.
                    It is shown when the last full quarter is chosen and it is
                    valid for current quarter.
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent style={{ display: 'block' }}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="h5" align="left">
                      PPM
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      color="textSecondary"
                    >
                      Parts Per Million
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="h6">
                        Value: {supplierScoring.PPM}
                      </Typography>
                      <Typography variant="h6">
                        Score: {supplierScoring.S_PPM}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    {supplierScoring.PPM_DIR !== null && (
                      <TrafficLightsBoxSC
                        direction={supplierScoring.PPM_DIR}
                        color={supplierScoring.PPM_LED}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent style={{ display: 'block' }}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="h5" align="left">
                      OTD
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      color="textSecondary"
                    >
                      On Time Delivery
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="h6">
                        Value: {supplierScoring.OTD}
                      </Typography>
                      <Typography variant="h6">
                        Score: {supplierScoring.S_OTD}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    {supplierScoring.OTD_DIR !== null && (
                      <TrafficLightsBoxSC
                        direction={supplierScoring.OTD_DIR}
                        color={supplierScoring.OTD_LED}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5">Total Score</Typography>
                {/* <Typography variant='body1' color='textSecondary'>
                  Score and Ranking
                </Typography> */}
                <Typography variant="body1" color="textSecondary">
                  Score
                </Typography>

                <Box mt={1}>
                  {/* <Typography variant='h6'>
                    Ranking: {supplierScoring.RANKING}
                  </Typography> */}

                  <Typography variant="h6">
                    Score: {supplierScoring.SCORE}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5">Claims</Typography>
                <Typography variant="body1" color="textSecondary">
                  Reclamations
                </Typography>
                <Box mt={1}>
                  <Typography variant="h6">
                    Value: {supplierScoring.CLAIMS}
                  </Typography>
                  <Typography variant="h6">
                    Score: {supplierScoring.S_CLAIMS}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5">NC-Parts</Typography>
                <Typography variant="body1" color="textSecondary">
                  Non Conforming Parts
                </Typography>
                <Box mt={1}>
                  <Typography variant="h6">
                    Value: {supplierScoring.NC_PARTS}
                  </Typography>
                  <Typography variant="h6">
                    Score: {supplierScoring.S_NC_PARTS}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h5">ADD</Typography>
                <Typography variant="body1" color="textSecondary">
                  Average Days Delayd
                </Typography>
                <Box mt={1}>
                  <Typography variant="h6">
                    Value: {supplierScoring.AD_DELAY}
                  </Typography>
                  <Typography variant="h6">
                    Score: {supplierScoring.S_AD_DELAY}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};
export default SupplierScoring;
