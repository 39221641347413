import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import GlobalContext from '../../context/global/globalContext';
import AuthContext from '../../context/auth/authContext';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Slide from '@material-ui/core/Slide';
import SkodaLogo from '../../img/logos/SKODA_logo1_white.png';
import InsertChartOutlinedRoundedIcon from '@material-ui/icons/InsertChartOutlinedRounded';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { UserRoutes } from '../../routes/RoutePaths';
import theme from '../../theme/theme';

const useStyles = makeStyles({
  root: {
    color: '#FFFFFF !important'
  },
  logo: {
    maxWidth: '200px',
    height: 'auto'
  },
  select: {
    width: '100px'
  },
  selectHidden: {
    display: 'none'
  },
  button: {
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: '400',
    margin: theme.spacing(1)
  },
  link: {
    textDecoration: 'none'
  },
  icon: {
    color: '#FFFFFF',
    fontSize: '1rem'
  },
  selected: {
    '& button': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
});

const Navbar = () => {
  // Global context
  const globalContext = useContext(GlobalContext);
  const {
    quarters,
    currentQuarter,
    setCurrentQuarter,
    getScoringLines,
    getSupplierScoring,
    clearState
  } = globalContext;

  // Authorization context
  const authContext = useContext(AuthContext);

  // Destructuring props from authContext
  const { logout, renewToken } = authContext;

  // Value for select input
  const [quarter, setQuarter] = useState(currentQuarter);

  // Outlined input border function
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  // React Router location
  let location = useLocation();

  // This gets called when component mounts
  useEffect(() => {
    setQuarter(currentQuarter);
    setLabelWidth(inputLabel.current.offsetWidth);
    // eslint-disable-next-line
  }, [currentQuarter]);

  // Handle change of the select input
  const handleChange = e => {
    setCurrentQuarter(e.target.value);
    setQuarter(e.target.value);
    getData(e.target.value);
  };

  const getData = async targetValue => {
    await renewToken();
    getSupplierScoring(targetValue);
    getScoringLines(targetValue);
  };

  const onLogout = () => {
    logout();
    clearState();
  };

  const classes = useStyles();

  return (
    <AppBar position='fixed' width='100%' color='primary'>
      <Toolbar>
        <img alt='logo' src={SkodaLogo} className={classes.logo} />
        <Grid
          item
          container
          direction='row'
          justify='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Slide
            direction='down'
            in={location.pathname === UserRoutes.Dashboard ? true : false}
          >
            <Grid item>
              <FormControl
                variant='outlined'
                className={classes.select}
                justify='center'
              >
                <InputLabel ref={inputLabel} className={classes.root}>
                  Quarter
                </InputLabel>
                <Select
                  className={classes.root}
                  value={quarter}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      name='quarter'
                      id='outlined-quarter-simple'
                    />
                  }
                >
                  {quarters.map(quarter => (
                    <MenuItem key={quarter} value={quarter}>
                      {quarter}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Slide>

          <Grid item>
            <NavLink
              data-testid='linkToDashboard'
              className={classes.link}
              to={UserRoutes.Dashboard}
              activeClassName={classes.selected}
            >
              <Button
                className={classes.button}
                startIcon={
                  <InsertChartOutlinedRoundedIcon className={classes.icon} />
                }
              >
                Dashboard
              </Button>
            </NavLink>
            <NavLink
              data-testid='linkToReclamations'
              className={classes.link}
              to={UserRoutes.Reclamations}
              activeClassName={classes.selected}
            >
              <Button
                className={classes.button}
                startIcon={<AssignmentOutlinedIcon className={classes.icon} />}
              >
                Reclamations
              </Button>
            </NavLink>
            <NavLink
              data-testid='linkToPerformance'
              className={classes.link}
              to={UserRoutes.Performance}
              activeClassName={classes.selected}
            >
              <Button
                className={classes.button}
                startIcon={<DescriptionOutlinedIcon className={classes.icon} />}
              >
                Performance
              </Button>
            </NavLink>
            <NavLink className={classes.link} to='/'>
              <Button
                onClick={onLogout}
                className={classes.button}
                startIcon={<ExitToAppOutlinedIcon className={classes.icon} />}
              >
                Logout
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
