import React, { useReducer } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import AdminContext from './adminContext';
import adminReducer from './adminReducer';
import setAuthToken from '../../utils/setAuthToken';
import { GET_USERS, ADD_USER, UPDATE_USER, DELETE_USER } from '../types';

const AdminState = props => {
  const initialState = {
    users: [],
    currentUser: null,
    loading: true
  };

  const [state, dispatch] = useReducer(adminReducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const getAllUsers = async () => {
    setAuthToken(sessionStorage.token);
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/users`);

      console.log(res.data);

      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addUser = async userData => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.token);
      try {
        const res = await axios.post(
          process.env.REACT_APP_API + '/users/add',
          userData
        );

        dispatch({
          type: ADD_USER,
          payload: res.data
        });

        enqueueSnackbar('New user created successfully.', {
          variant: 'success'
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to create new user.', {
          variant: 'error'
        });
      }
    }
  };

  const updateUser = async userData => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.token);
      try {
        const res = await axios.put(
          process.env.REACT_APP_API + '/users/update',
          userData
        );

        dispatch({
          type: UPDATE_USER,
          payload: res.data
        });

        enqueueSnackbar('User edited successfully.', {
          variant: 'success'
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to edit user.', {
          variant: 'error'
        });
      }
    }
  };

  const deleteUser = async userId => {
    if (sessionStorage.token) {
      setAuthToken(sessionStorage.token);

      try {
        await axios.delete(
          process.env.REACT_APP_API + `/users/remove/${userId}`
        );
        dispatch({
          type: DELETE_USER,
          payload: userId
        });
        enqueueSnackbar('User deleted successfully.', {
          variant: 'success'
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to delete user.', {
          variant: 'error'
        });
      }
    }
  };

  return (
    <AdminContext.Provider
      value={{
        users: state.users,
        currentUser: state.currentUser,
        loading: state.loading,
        getAllUsers,
        addUser,
        updateUser,
        deleteUser
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
