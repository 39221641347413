import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../context/global/globalContext';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  GroupingPanel,
  DragDropProvider,
  TableGroupRow,
  TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
  SortingState,
  GroupingState,
  IntegratedGrouping,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { Card, CardHeader, Fade, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    padding: 0
  }
}));

const ScoringLines = () => {
  const globalContext = useContext(GlobalContext);
  const { scoringLines } = globalContext;

  const [checked, setChecked] = useState(false);

  const [state] = useState({
    columns: [
      { name: 'ORDERID', title: 'Order ID' },
      { name: 'ITEMID', title: 'Item ID' },
      { name: 'NAME1', title: 'Item Name' },
      { name: 'QTY', title: 'Quantity' },
      { name: 'DELIVERY_DATE', title: 'Delivery Date' },
      { name: 'REC_DATE', title: 'Record Date' },
      { name: 'LATE_DAYS', title: 'Late Days' },
      { name: 'TYPE', title: 'Type' }
    ],
    dateColumns: ['DELIVERY_DATE', 'REC_DATE'],
    dateFilterOperations: ['contains', 'startsWith', 'endsWith'],
    numericColumns: ['LATE_DAYS', 'QTY'],
    numericFilterOperations: [
      'equal',
      'notEqual',
      'greaterThan',
      'greaterThanOrEqual',
      'lessThan',
      'lessThanOrEqual'
    ],
    stringFilterOpertions: ['contains', 'startsWith', 'endsWith'],
    pageSizes: [15, 25, 50],
    tableColumnExtensions: [
      { columnName: 'QTY', align: 'center' },
      { columnName: 'DELIVERY_DATE', align: 'center' },
      { columnName: 'REC_DATE', align: 'center' },
      { columnName: 'LATE_DAYS', align: 'center' },
      { columnName: 'TYPE', align: 'center' }
    ]
  });

  useEffect(() => {
    setChecked(true);
  }, []);

  const classes = useStyles();

  return (
    <Fade in={checked}>
      <Container maxWidth="xl" className={classes.container}>
        <Card>
          <CardHeader title="Scoring Lines" subheader="List of scoring lines" />
          <Grid rows={scoringLines} columns={state.columns}>
            <DragDropProvider />
            <DataTypeProvider
              for={state.dateColumns}
              availableFilterOperations={state.dateFilterOperations}
            />
            <DataTypeProvider
              for={state.numericColumns}
              availableFilterOperations={state.numericFilterOperations}
            />
            <SortingState />
            <IntegratedSorting />
            <GroupingState />
            <IntegratedGrouping />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} defaultPageSize={15} />
            <IntegratedPaging />
            <Table columnExtensions={state.tableColumnExtensions} />
            <TableGroupRow />
            <TableHeaderRow showSortingControls />
            <TableFilterRow showFilterSelector={true} />
            <Toolbar />
            <GroupingPanel showSortingControls />
            <PagingPanel pageSizes={state.pageSizes} />
          </Grid>
        </Card>
      </Container>
    </Fade>
  );
};

export default ScoringLines;
