import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

const CustomText = styled(Typography)`
  text-align: center;
  color: #fff;
  font-weight: 500;
`;

const CustomBox = styled(Box)`
  height: 100px;
  width: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: ${props =>
    props.status === 'F'
      ? '#F00000'
      : props.status === 'P'
      ? '#00B050'
      : props.status === 'E'
      ? '#0295FA'
      : '#A9A9A9'};
`;

const StatusBoxSC = ({ status }) => {
  return (
    <Tooltip
      arrow
      title={
        status === 'F'
          ? 'Fail (F)'
          : status === 'P'
          ? 'Pass (P)'
          : status === 'E'
          ? 'Excellent (E)'
          : 'Not available'
      }
    >
      <CustomBox status={status}>
        <CustomText variant='h2'>
          {status === 'F' || status === 'P' || status === 'E' ? status : '-'}
        </CustomText>
      </CustomBox>
    </Tooltip>
  );
};

export default StatusBoxSC;
