import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SkodaLogo from '../../img/logos/SKODA_logo1_white.png';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import theme from '../../theme/theme';

const useStyles = makeStyles({
  root: {
    color: '#FFFFFF !important'
  },
  logo: {
    maxWidth: '200px',
    height: 'auto'
  },
  select: {
    width: '100px'
  },
  selectHidden: {
    display: 'none'
  },
  button: {
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: '400',
    margin: theme.spacing(1)
  },
  link: {
    textDecoration: 'none'
  },
  icon: {
    color: '#FFFFFF',
    fontSize: '1rem'
  },
  selected: {
    '& button': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
});

const AdminNavbar = () => {
  // Authorization context
  const authContext = useContext(AuthContext);

  // Destructuring props from authContext
  const { logout } = authContext;

  const onLogout = () => {
    logout();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" width="100%" color="primary">
        <Toolbar>
          <img alt="logo" src={SkodaLogo} className={classes.logo} />
          <Grid
            item
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="body1"
                style={{ textTransform: 'uppercase', fontWeight: 400 }}
              >
                Admin Dashboard
              </Typography>
            </Grid>
            <Grid item>
              <NavLink className={classes.link} to="/">
                <Button
                  onClick={onLogout}
                  className={classes.button}
                  startIcon={<ExitToAppOutlinedIcon className={classes.icon} />}
                >
                  Logout
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AdminNavbar;
