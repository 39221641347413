import { createMuiTheme } from '@material-ui/core/styles';
import KlavikaLight from './fonts/klavika/Klavika-Light.woff';
import KlavikaRegular from './fonts/klavika/Klavika-Regular.woff';
import KlavikaMedium from './fonts/klavika/Klavika-Medium.woff';
import KlavikaBold from './fonts/klavika/Klavika-Bold.woff';

const Klavika_Light = {
  fontFamily: 'Klavika',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `local('Klavika'),
        local('Klavika-Light'),
        url(${KlavikaLight}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

const Klavika_Regular = {
  fontFamily: 'Klavika',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `local('Klavika'),
        local('Klavika-Regular'),
        url(${KlavikaRegular}) format('woff')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

const Klavika_Medium = {
  fontFamily: 'Klavika',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `local('Klavika'),
        local('Klavika-Medium'),
        url(${KlavikaMedium}) format('woff')`
};

const Klavika_Bold = {
  fontFamily: 'Klavika',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `local('Klavika'),
  local('Klavika-Bold'),
  url(${KlavikaBold}) format('woff')`
};

const theme = createMuiTheme({
  palette: {
    primary: { main: '#003f7e' },
    secondary: { main: '#0096d6' },
    green: '#00B050',
    red: '#F00000'
  },
  typography: {
    fontFamily: 'Klavika'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          Klavika_Light,
          Klavika_Regular,
          Klavika_Medium,
          Klavika_Bold
        ]
      }
    },
    MuiCard: {
      root: {
        boxShadow:
          '0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
        borderRadius: '10px'
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '16px'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#0096d6',
          color: '#FFFFFF'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#0096d6',
          borderWidth: '2px',
          transition: '0.3s ease-in-out',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: '#0096d6'
          }
        },
        '&$focused $notchedOutline': {
          borderColor: '#0096d6',
          borderWidth: '2px',
          color: '#FFFFFF'
        }
      }
    }
  }
});

export default theme;
